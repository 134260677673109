<template>
  <page>
    <template slot="headerInfo">
      <el-button
        v-auth="
          `${
            isMiddleMan
              ? 'middle-purchase:downMiddleShippedList'
              : 'supplier:downShippedList'
          }`
        "
        :disabled="!tableData.length"
        @click="exportData(false)"
        type="primary"
      >
        下载发货单
      </el-button>
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="下单商户" prop="merchantName">
          <el-input v-model="params.merchantName" placeholder="下单商户" />
        </el-form-item>
        <el-form-item v-if="!isMiddleMan" label="门店分组" prop="groupManageId">
          <el-select v-model="params.groupManageId" clearable>
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货车辆" prop="licensePlate">
          <el-select v-model="params.licensePlate" clearable>
            <el-option
              v-for="item in carList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip
          min-width="150"
          prop="purchaseNo"
          label="订单编号"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="150"
          prop="merchantName"
          label="下单商户"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="150"
          prop="payPrice"
          label="订单金额(元)"
        />

        <el-table-column show-overflow-tooltip min-width="200" label="收货信息">
          <template slot-scope="{ row }">
            <div class="addressDetail">
              <p>
                <span class="label">收货人:</span>
                <span class="maxText">{{ row.cargoReceiver || '-' }}</span>
              </p>
              <p>
                <span class="label">联系方式:</span>
                <span class="maxText">{{ row.phone || '-' }}</span>
              </p>
              <p>
                <span class="label">收货地址:</span>
                <span class="maxText">{{ row.storeAddress || '-' }}</span>
              </p>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          min-width="200"
          prop="arrivalTimeStr"
          label="发货时间"
        />

        <el-table-column width="200" label="操作" fixed="right">
          <template slot-scope="{ row }">
            <el-button
              v-auth="
                `${
                  isMiddleMan
                    ? 'middle-purchase:detailList'
                    : 'supply-purchase-detail:detail'
                }`
              "
              @click="detailHandler(row)"
              type="text"
              size="mini"
            >
              商品详情
            </el-button>
            <el-button
              v-auth="
                `${
                  isMiddleMan
                    ? 'middle-purchase:downMiddleShippedList'
                    : 'supplier:downShippedList'
                }`
              "
              @click="exportData(row.id)"
              type="text"
              size="mini"
            >
              下载发货单
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <DetailModal
      v-if="detailVisible"
      :visible.sync="detailVisible"
      :currentItem="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './components/DetailModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      isDetail: false,
      visible: false,
      detailVisible: false,
      currentItem: null,
      loading: false,
      groupList: [],
      carList: [],
      tableData: [],

      multipleSelection: [],
      params: {
        limit: 20,
        page: 1,
        groupManageId: '',
        merchantName: '',
        date: []
      },
      totalCount: 0
    }
  },
  components: {
    DetailModal
  },
  computed: {
    isMiddleMan() {
      return localStorage.getItem('userType') == 'MIDDLEMAN'
    }
  },
  created() {
    if (!this.isMiddleMan) {
      this.getGroupList()
    }

    this.getCarList()
  },
  methods: {
    async getGroupList() {
      try {
        const res = await this.$api.common.getGroupAllList()
        this.groupList = (res.groupManages || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {}
    },
    async getCarList() {
      try {
        const res = await this.$api.carManage.getCarAllList()

        this.carList = res.vehicles.map(item => ({
          label: item.licensePlate,
          value: item.id
        }))
      } catch (e) {
        console.log(e)
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.purchaseNo)
    },

    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        for (let i in params) {
          if (params[i] === '') params[i] = undefined
        }
        if (params.date && params.date.length) {
          params.arrivalStartTime = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.arrivalEndTime = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD'
          )
        } else {
          params.arrivalStartTime = undefined
          params.arrivalEndTime = undefined
        }
        const { date, ...sendData } = params
        const res = await this.$api.orderList.getOrderDownLoadList(sendData)
        this.tableData = ((res.page && res.page.list) || []).map(item => {
          item.totalPrice = Number(
            (item.supplyPrice || 0) * (item.goodsNum || 0)
          ).toFixed(2)

          item.merchantName = item.merchantName || item.storesName

          item.arrivalTimeStr = this.$utils.dateFormat(
            item.arrivalTime,
            'YYYY-MM-DD HH:mm:ss'
          )

          item.storeAddress =
            (item.provinceName || '') +
            (item.cityName || '') +
            (item.regionName || '') +
            (item.address || '')

          return item
        })
        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    detailHandler(row) {
      this.currentItem = row
      this.detailVisible = true
    },

    async exportData(id) {
      try {
        this.loading = true

        const params = { ...this.params }

        if (params.date && params.date.length) {
          params.arrivalStartTime = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.arrivalEndTime = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD'
          )
        } else {
          params.arrivalStartTime = undefined
          params.arrivalEndTime = undefined
        }

        await this.$api.orderList.exportOrderDownLoadList({
          arrivalStartTime: params.arrivalStartTime,
          arrivalEndTime: params.arrivalEndTime,
          ids: id
            ? [id]
            : this.multipleSelection.length
            ? this.multipleSelection
            : undefined,
          purchaseNo: params.purchaseNo || undefined,
          orderStatus: params.orderStatus || undefined
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
