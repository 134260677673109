<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="商品详情"
    :visible.sync="show"
    width="1000px"
  >
    <!-- <h2>采购商品列表</h2> -->
    <el-table
      border
      v-loading="loading"
      :data="tableData"
      max-height="300"
      style="width: 100%"
    >
      <el-table-column show-overflow-tooltip prop="goodsName" label="商品名称">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="barNo" label="条形码">
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="purchaseUnit"
        label="采购单位"
      >
      </el-table-column>
      <el-table-column
        prop="supplyPrice"
        label="采购单价(元)"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="goodsNum"
        label="采购数量"
      ></el-table-column>
      <el-table-column show-overflow-tooltip label="金额小计(元)">
        <template slot-scope="{ row }">
          <span>{{ Number(row.payPrice || 0).toFixed(2) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- <h2 style="margin-top: 40px">赠送商品列表</h2>
    <el-table
      border
      v-loading="loading"
      :data="tableData2"
      max-height="300"
      style="width: 100%"
    >
      <el-table-column show-overflow-tooltip prop="goodsName" label="商品名称">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="barNo" label="条形码">
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="purchaseUnit"
        label="采购单位"
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="presentNum"
        label="赠送数量"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="activityName"
        label="活动名称"
      ></el-table-column>
    </el-table> -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      params: {
        limit: 1000,
        page: 1
      },
      tableData: [],
      tableData2: []
    }
  },
  created() {
    this.getData()
  },
  computed: {
    isMiddleMan() {
      return localStorage.getItem('userType') == 'MIDDLEMAN'
    }
  },
  methods: {
    async getData() {
      try {
        this.loading = true

        const sendData = { ...this.params }

        sendData.purchaseId = this.currentItem.id

        if (this.isMiddleMan) {
          const res = await this.$api.storeProcurementOrders.getDetailList({
            purchaseId: this.currentItem.id
          })

          if (res.detail) {
            this.tableData = res.detail.middlePurchaseDetailVoList.map(item => {
              item.supplyPrice = item.inPrice

              return item
            })
          }
        } else {
          const res = await this.$api.orderList.getOrderDetail(sendData)
          this.tableData = ((res.page && res.page.list) || []).map(item => {
            const targetItem = item.goods || {}

            return {
              goodsName: targetItem.goodsName,
              barNo: targetItem.barNo,
              purchaseUnit: targetItem.purchaseUnit,
              supplyPrice: targetItem.supplyPrice,
              goodsNum: item.goodsNum,
              payPrice: item.payPrice
            }
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  margin: 10px;
  font-size: 15px;
}
</style>
